import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Field } from 'formik'
import './styles.scss'

const Error = loadable(() => import('/src/components/Form/Error'))
const Required = loadable(() =>
    import('/src/components/Form/Required/Required')
)

const CheckBox = ({
    label,
    name,
    isSubmitting,
    isSubmitted,
    onChange,
    required
}) => {
    return (
        <div className="c-checkbox" onChange={onChange}>
            <div className="c-checkbox__inner">
                <Field
                    type={'checkbox'}
                    name={name}
                    id={name}
                    disabled={isSubmitting || isSubmitted}
                />

                <label htmlFor={name}>
                    {label}
                    {required && <Required />}
                </label>
            </div>

            <Error name={name} />
        </div>
    )
}

CheckBox.propTypes = {
    /**
     * Checkbox label
     */
    label: PropTypes.string.isRequired,
    /**
     * Checkbox name
     */
    name: PropTypes.string.isRequired,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Whether field is required
     */
    required: PropTypes.bool,
    /**
     * onChange function
     */
    onChange: PropTypes.func
}

CheckBox.defaultProps = {
    label: 'I would no longer like to receive communications by post',
    name: 'consentPost',
    isSubmitting: false,
    isSubmitted: false,
    required: false,
    onChange: undefined
}

export default CheckBox
